"use strict";

import {on,hasClass} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind('.js-dropdown-persist', function (dropdown) {
        const dropdownClickHandler = (evt) => {
            if (!hasClass('js-close-dropdown', evt.target) ){
                evt.stopPropagation();
            }
        };
        on('click', (evt) => dropdownClickHandler(evt), dropdown);
    });
}