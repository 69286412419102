import {on, findIn, find, addClass, removeClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-change-phone__link', function (baseElement) {
        if (matchMedia('(min-width: 768px)').matches) {
            let phoneBtn = find('.js-change-phone__btn');
            on('click', function (e){
                e.preventDefault();
                addClass('d-none', baseElement);
                removeClass('d-none', phoneBtn);
            }, baseElement)
        }
    });
}

