"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {ISOStringToDate, UTCDateToLocalDate, dateToISOString, localDateToUTCDate} from "@elements/date-utils";
import {findIn, trigger, on, off} from "@elements/dom-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils";

//added "enable" in function transformDataOptions

const defaultOptions = {
    mode: "single",
    minDate: "today",
    showMonths: 1,
    disableMobile: "true",
    appendAltTime: true
};

const defaultSelectors = {
    base: '.js-datepicker',
    input: '.js-datepicker__input',
    altField: '.js-datepicker__alt-field'
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createDatepicker(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createDatepicker(baseElement, defaultOptions, selectors) {
    let datepicker = null;
    let input = findIn(selectors.input, baseElement);
    let altField = findIn(selectors.altField, baseElement);

    let options = {
        locale: _config.lang,
        formatDate: dateObj => dateObj.toLocaleDateString(_config.lang),
        ...defaultOptions,
        ...transformDataOptions(getPrefixedDataSet('datepicker', baseElement)),
        onChange: function (selectedDates, dateStr, instance) {
            if (selectedDates.length !== 0){
                altField.value = dateToISOString(localDateToUTCDate(selectedDates[0]), options.appendAltTime);
            }else{
                altField.value = '';
            }
        },
    };

    // on mobile only 1 month always because of flatpickr bug
    if(matchMedia('(max-width: 767px)').matches){
        options = {...options, ...{
                showMonths: 1
            }}
    }

    return loadFlatPickr().then(function () {
        datepicker = input.flatpickr(options);

        if(altField.value) {
            datepicker.setDate(new Date(altField.value));
        }

        function setMinDate(date) {
            datepicker.set('minDate', date);

            if (getDate(input) && getDate(input).getTime() < date.getTime()) {
                setDate(date);
            }
        }

        function setMaxDate(date) {
            datepicker.set('maxDate', date);

            if (getDate(input) && getDate(input).getTime() > date.getTime()) {
                setDate(date);
            }
        }

        function setEnable(dates) {
            datepicker.set('enable', dates);
        }

        function reset() {
            datepicker.clear();
            datepicker.input.value = "";
            altField.value = "";
            trigger('change', input);
        }

        function getDate() {
            return ISOStringToDate(findIn(selectors.altField, baseElement).value);
        }

        function setDate(date) {
            datepicker.setDate(new Date(date));
            datepicker.input.value = new Date(date).toLocaleDateString(_config.lang);
            altField.value = dateToISOString(localDateToUTCDate(date), options.appendAltTime);
            trigger('change', input);
        }

        function getInput() {
            return findIn(selectors.input, baseElement);
        }

        function getDatepickerInstance() {
            return datepicker;
        }

        let api = {
            getDate,
            setMinDate,
            setMaxDate,
            setEnable,
            setDate,
            getInput,
            reset,
            getDatepickerInstance
        };

        baseElement.datepicker = api;
        trigger(INITIALIZED_EVENT, baseElement);

        return api;
    });
}

function transformDataOptions(options) {
    options = {...options};

    if (options.minDate && typeof options.minDate === 'string') {
        options.minDate = UTCDateToLocalDate(ISOStringToDate(options.minDate));
    }

    if (options.maxDate && typeof options.maxDate === 'string') {
        options.maxDate = UTCDateToLocalDate(ISOStringToDate(options.maxDate));
    }

    if (options.appendAltTime && typeof options.appendAltTime === 'string') {
        options.appendAltTime = options.appendAltTime === 'true';
    }

    if(options.enable && typeof  options.enable === 'string') {
        options.enable = JSON.parse(options.enable);
    }

    return options;
}

const INITIALIZED_EVENT = 'datepicker/initialized';

export function getApi(element) {
    if(element.datepicker) {
        return Promise.resolve(element.datepicker);
    } else {
        return new Promise(function(resolve, reject) {
            function initializeHandler() {
                resolve(element.datepicker);
                off(INITIALIZED_EVENT, initializeHandler, element);
            }
            on(INITIALIZED_EVENT, initializeHandler, element);
        });
    }
}

let promise;
export function loadFlatPickr() {
    if (promise) {
        return promise;
    }

    promise = new Promise(function(resolve, reject) {
        import("flatpickr").then(function () {
            if (_config.lang && _config.lang !== 'en') {
                import('flatpickr/dist/l10n/' + _config.lang + '.js').then(function () {
                    resolve();
                }).catch(function () {
                    resolve();
                });
            } else {
                resolve();
            }
        }).catch(function (error) {
            reject();
        });
    });

    return promise;
}
