"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {onEnterViewPort} from "@elements/viewport-utils";
import {matches} from "@elements/dom-utils";
import {on, find, findAllIn, hasClass, findIn, closest, addClass, removeClass} from "@elements/dom-utils";
import Pristine from "../libs/pristine/pristine.js";

const defaultOptions = {
    classTo: 'form-group',
    errorClass: 'has-error',
    successClass: 'has-success',
    errorTextParent: 'form-group',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass: 'form-errors invalid-feedback'
};

const defaultSelectors = {
    base: '.js-form-validation'
};

let pristineForms = [];


export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createFormValidation(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createFormValidation(form, options, selectors) {

    onEnterViewPort(form, function () {
        let pristine = new Pristine(form, options);

        if(form.getAttribute('data-form-validation-with-disabled-submit') || hasClass('js-form-validation-with-disabled-submit', form)) {
            toggleSubmitButtonClass(form, pristine);

            on('change', function() {
                toggleSubmitButtonClass(form, pristine);
            }, form);
        }

        form.addEventListener('submit', function (e) {
            // check if the form is valid

            //needed for payment
            if(e.target.getAttribute('data-form-validation-revalidate')) {
                pristine = new Pristine(e.target, options);
            }

            if (!pristine.validate()){
                // e.stopImmediatePropagation();
                e.preventDefault();
            }
        });

        onFind('.js-form-validation-required-with-element', function (input) {
            let requiredElementSelector = input.getAttribute('data-form-validation-required-with-element') || false;
            if(requiredElementSelector) {
                let requiredElem = findIn("#"+requiredElementSelector, form);
                if(requiredElem) {
                    on('input', function(evt) {
                        if(requiredElem.value.length > 0) {
                            input.required = true;
                        } else {
                            input.required = false;
                        }
                    }, requiredElem);
                }
            }
        });
        // Promise.all([import('pristinejs')]).then((Pristine) => {
        //     const pristine = new Pristine[0].default(form, options);
        //
        //     form.addEventListener('submit', function (e) {
        //         // check if the form is valid
        //         if (!pristine.validate()){
        //             e.preventDefault();
        //         }
        //     });
        // });
    });

}

export function isValidationForm (form) {
    return matches('.js-form-validation', form);
}

export function isValid(form) {
    if(matches('form', form)){
        const pristine = new Pristine(form, defaultOptions);
        return pristine.validate();
    }else{
        return 'Using pristine without form';
    }
}

export function validateForm(form) {
    if(matches('form', form)){
        const pristine = new Pristine(form, defaultOptions);
        pristine.validate();
    }else{
        console.log('Using pristine without form');
    }
}


function toggleSubmitButtonClass(form, pristine) {
    let formElements = form.querySelectorAll('input:not([type="hidden"]), select');
    
    let wrapper = closest('.js-form-validation__wrapper', form);
    let submitButtons = findAllIn('[type="submit"]', wrapper);

    let footerBar = find('.checkout-footer-bar');


    if(!pristine.validate(formElements, true)) {
        submitButtons.map(function(btn) {
            if(!hasClass('js-form-validation__submit-ignore-validation-style', btn)) {
                addClass('form-validation-not-valid', btn) ;
            }
        });

        if(footerBar) {
            let submitButtonsFooterBar = findAllIn('[type="submit"]', footerBar);
            submitButtonsFooterBar.map(function(btn) {
                if(!hasClass('js-form-validation__submit-ignore-validation-style', btn)) {
                    addClass('form-validation-not-valid', btn) ;
                }
            });
        }

    } else {
        submitButtons.map(function(btn) {
            removeClass('form-validation-not-valid', btn) ;
        });

        if(footerBar) {
            let submitButtonsFooterBar = findAllIn('[type="submit"]', footerBar);
            submitButtonsFooterBar.map(function(btn) {
                removeClass('form-validation-not-valid', btn) ;
            });
        }
    }
}