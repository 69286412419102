
"use strict";

import {onFind,initInScope} from "@elements/init-modules-in-scope";

export function init () {
    onFind('.js-collapse-opening-hours', function (element){

        element.addEventListener('shown.bs.collapse', function () {
            initInScope(element);
        })
    });


}