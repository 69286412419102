"use strict";

import {closest,trigger} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

export function init () {

    onFind('.js-multiple-select-dropdown', function (dropdown) {
        dropdown.addEventListener('hide.bs.dropdown', function (element) {
            let dropdown = element.target;
            let form = closest('form', dropdown);

            if (dropdown.getAttribute('data-multiple-select-dropdown-submit-on-hide') === 'true'){
                trigger('submit',form);

            }
        });

    });

}