"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {on, findIn, trigger} from "@elements/dom-utils";

export function init() {
    onFind('.js-ajax-form', function (element) {
        on('reset.ajax-form', function () {
            let form = findIn('.js-ajax-form__form', element);
            trigger('submit', form);
        }, element);
    });
}
